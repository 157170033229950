import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ImgGenerator from "./RandomElements/RandomImageGenerator";
import StarGenerator from "./Stars/StarGenerator";
import Balloon from "./Balloon/Balloon";
import Background from "./Background/Background";
import Notifier from "../components/common/Notifier/Notifier";
import DemoBtn from "./DemoBtn/DemoBtn";
import {getRoundId} from "../../redux/actions/controller/actions";
import {useTranslation} from "react-i18next";
import FadeNumber from "./FadeNumber/FadeNumber";
import Multiplier from "./Multiplier/Multiplier";

const Animation = () => {
    const dispatch = useDispatch();
    const {state, crashedMultiplier, multiplier, bettingTime, sprite} = useSelector(state => state.game.round);
    const isMobileLandscape = useSelector(state => state.user.isMobileLandscape);
    const boxes = useSelector(state => state.controller.boxes);
    const notification = useSelector(state => state.game.notification);
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);
    const animationWrapperRef = useRef(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (state === "betting") {
            pauseAllAnimations(false);
            dispatch(getRoundId());
        }
        if (state === 'start') {
            dispatch(getRoundId());
        }
        if (state === 'crashed') {
            pauseAllAnimations(true);
        }

    }, [state, dispatch]);
    const pauseAllAnimations = (stop) => {
        if (stop) {
            animationWrapperRef?.current?.classList.add('pause-animations')
        } else {
            animationWrapperRef?.current?.classList.remove('pause-animations');
        }

    }

    return <div ref={animationWrapperRef} className="animation animation__content">
        <div
            className={`animation__wrapper ${(isMobileLandscape && Object.values(boxes).every(item => item.isActive)) ? "animation__wrapper--small" : ''}`}>
            {
                !isAnimationActive && <div>
                    {
                        state === 'betting' && <>
                            <div className="animation__disabled-betting-timer-title">{t('animation.betting_time')}</div>
                            <div className="animation__disabled-betting-timer">{bettingTime}</div>
                        </>
                    }
                    {
                        state === 'crashed' && <>
                            <div className="animation__multiplier__disabled--title">{t('animation.result')}</div>
                            <div className="animation__multiplier__disabled--crashed">x{parseFloat(crashedMultiplier).toFixed(2)}</div>
                        </>
                    }
                    {
                        state === 'playing' && <>
                            <div className="animation__multiplier__disabled--title">{t('animation.multiplier')}</div>
                            <div className="animation__multiplier__disabled">x{parseFloat(multiplier).toFixed(2)}</div>
                        </>
                    }
                    {
                        state === 'start' && <>
                            <div className="animation__multiplier__disabled--title">{t('animation.multiplier')}</div>
                            <div className="animation__multiplier__disabled">x1.00</div>
                        </>
                    }

                </div>
            }

            <div style={{opacity: isAnimationActive ? 1 : 0}}>
                <Background animate={state === 'playing' || state === 'crashed'}/>
                {
                    (state === 'playing' || state === 'crashed') && <>
                        <StarGenerator/>
                        <ImgGenerator/>
                    </>
                }
                {state === 'betting' && <FadeNumber number={bettingTime}/>}
                <div>
                    <Balloon
                        multiplier={multiplier}
                        sprite={sprite}
                        state={state}
                    />
                    <Multiplier
                        multiplier={multiplier}
                        crashedMultiplier={crashedMultiplier}
                        sprite={sprite}
                    />
                </div>

            </div>
            <DemoBtn/>
        </div>
        {notification.status && <Notifier message={notification.message} status={notification.status}/>}
    </div>

}
export default Animation;