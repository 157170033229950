import combinedActions from "../redux/actions/combinedActions";
import {gameSendNotification, setLoader} from "../redux/actions/game/actions";

class UserSockets {
    constructor() {
        this.socket = null;
    }

    onConnect(socket) {
        this.socket = socket;
    }

    disconnectSocket(dispatch) {
        this.socket = null; // Clear the socket instance
        this._triggerLoader(dispatch, "reload_page");
    }

    onConnectionError(dispatch, error = "connection_error") {
        console.error("Socket Connection Error:", error);
        this.socket = null; // Ensure socket is reset on error
        this._triggerLoader(dispatch, 'connection_error');
    }

    handleGameSocketEvents({ action, payload, isDispatch }, dispatch) {
        if (!action || !combinedActions[action]) {
            console.warn(`Invalid action received: ${action}`);
            return;
        }
        isDispatch ? dispatch(combinedActions[action](payload)) : combinedActions[action](payload);
    }

    _triggerLoader(dispatch, message) {
        dispatch(setLoader({ gameLoader: true }));
        dispatch(gameSendNotification({ type: 'error', message, labelFor: 'loader' }));
    }
}

const UserSocket = new UserSockets();
export default UserSocket
