import HeaderMobile from "../components/mobile/HeaderMobile";
import HeaderDesktop from "../components/desktop/HeaderDesktop";
import React from "react";
import TournamentHeader from "../components/mobile/TournamentHeader";
import HeaderBurgerMenu from "../components/mobile/HeaderBurgerMenu";
import {useSelector} from "react-redux";

const Header = React.memo(function ({isMobile}) {
    const isDemoActive= useSelector(state => state.user.isDemoActive);

    return <>
        {
            isMobile ? <>
                <HeaderMobile/>
                {!isDemoActive && <TournamentHeader/> }
                <HeaderBurgerMenu/>
            </> : <HeaderDesktop/>
        }
    </>

}, (prevProps, nextProps) => prevProps.isMobile === nextProps.isMobile)
export default Header;